<template>
  <v-dialog v-model="dialog" max-width="1000px" @keydown.esc="cancel">
    <v-card class="mt-5">
    <h1>BODAS POR HACER</h1>
    <v-divider></v-divider>
    <v-data-table fixed-header :items="eventos.filter(e => new Date(e.diaReal) >= new Date())" :headers="eventosHeaders" class="fixed-checkbox" item-key="idAdHoc"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, -1],
          }" checkbox-color="secondary" style="cursor:pointer">
      <template v-slot:body.prepend="{ headers }">
        <table-filters :headers="headers" :items="eventos" v-model="inlineFilters"></table-filters>
      </template>
      <template v-slot:item="{ item, index }">
        <tr @click="select(item)">
          <td class="espaciador"></td>
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{ index+1 }}</td>
          <td style="white-space: nowrap !important;">{{ item.dia }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.year }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.horario }}</td>
          <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{ item.confirmada }}</td>
          <td style="white-space: nowrap !important;">{{ item.nombrePareja }}</td>
          <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
          <td style="white-space: nowrap !important;">{{ item.salon }}</td>
          <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
          <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
          <td style="white-space: nowrap !important;">{{ item.total }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoBoda }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoEnlace }}</td>
          <td style="white-space: nowrap !important;">{{ item.ubicacionEnlace }}</td>
          <td style="white-space: nowrap !important;">{{ item.aperitivo }}</td>
          <td style="white-space: nowrap !important;">{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { day : 'numeric'} ) }}-{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { month : 'short'} ).toUpperCase() }}</td>
          <td style="white-space: nowrap !important;">{{ item.breveDato }}</td>
          <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.habitaciones }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.contrato }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.fianza }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.fechaPrueba }}</td>
        </tr>
      </template>
    </v-data-table>
    </v-card>

    <v-card class="mt-5">
      <h1>BODAS EFECTUADAS</h1>
      <v-divider></v-divider>
      <v-data-table fixed-header :items="eventos.filter(e => new Date(e.diaReal) < new Date())" :headers="eventosHeaders4" class="fixed-checkbox" item-key="idAdHoc"
        :single-select="true" :loading="loading" :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [0, -1],
            }" checkbox-color="secondary" style="cursor:pointer">
        <template v-slot:body.prepend="{ headers }">
          <table-filters :headers="headers" :items="eventos" v-model="inlineFilters4"></table-filters>
        </template>
        <template v-slot:item="{ item, index }">
          <tr @click="select(item)">
            <td class="espaciador">
              {{ item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0] }}
            </td>
            <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{ index+1 }}</td>
            <td style="white-space: nowrap !important;">{{ item.dia }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.year }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.horario }}</td>
            <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{ item.confirmada }}</td>
            <td style="white-space: nowrap !important;">{{ item.nombrePareja }}</td>
            <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
            <td style="white-space: nowrap !important;">{{ item.salon }}</td>
            <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
            <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
            <td style="white-space: nowrap !important;">{{ item.total }}</td>
            <td style="white-space: nowrap !important;">{{ item.tipoBoda }}</td>
            <td style="white-space: nowrap !important;">{{ item.tipoEnlace }}</td>
            <td style="white-space: nowrap !important;">{{ item.ubicacionEnlace }}</td>
            <td style="white-space: nowrap !important;">{{ item.aperitivo }}</td>
            <td style="white-space: nowrap !important;">{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { day : 'numeric'} ) }}-{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { month : 'short'} ).toUpperCase() }}</td>
            <td style="white-space: nowrap !important;">{{ item.breveDato }}</td>
            <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
            <td style="white-space: nowrap !important;text-align: center;">{{ item.habitaciones }}</td>
            <td style="white-space: nowrap !important;text-align: center;">{{ item.contrato }}</td>
            <td style="white-space: nowrap !important;text-align: center;">{{ item.fianza }}</td>
            <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
              item.fechaPrueba }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { perColumnFilter } from "@/utils";

export default {
  components: {
    SetObjetivo: () => import("@/components/SetObjetivo.vue"),
    NewAdHoc: () => import("@/components/NewAdHoc.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      inlineFilters: {
        idAdHoc: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFilters4: {
        idAdHoc: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      eventos: [],
      loading: false,
    };
  },
  computed: {
    eventosHeaders() {
      return [
        { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center", },
        { text: "Nº", class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "AÑO", value: "year", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "Hº", value: "horario", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONF", value: "confirmada", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "NOMBRE PAREJA", value: "nombrePareja", class: "text-no-wrap sticky-header" },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SALÓN", value: "salon", class: "text-no-wrap sticky-header" },
        { text: "ADULTOS", value: "adultos", class: "text-no-wrap sticky-header text-center", },
        { text: "NIÑOS", value: "ninos", class: "text-no-wrap sticky-header text-center", },
        { text: "TOTAL", value: "total", class: "text-no-wrap sticky-header text-center", },
        { text: "TIPO BODA", value: "tipoBoda", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "TIPO ENLACE", value: "tipoEnlace", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "UBICACIÓN ENLACE", value: "ubicacionEnlace", class: "text-no-wrap sticky-header" },
        { text: "APERITIVO", value: "aperitivo", class: "text-no-wrap sticky-header" },
        { text: "FECHA RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "fechaReserva" },
        { text: "BREVE DATO", value: "breveDato", class: "text-no-wrap sticky-header" },
        { text: "ORIGEN RESERVA", value: "origenReserva", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "HABITACIONES", value: "habitaciones", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONTRATO", value: "contrato", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "FIANZA", value: "fianza", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "PB", value: "fechaPrueba", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    eventosHeaders4() {
      return [
        { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center", },
        { text: "Nº", class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "AÑO", value: "year", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "Hº", value: "horario", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONF", value: "confirmada", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "NOMBRE PAREJA", value: "nombrePareja", class: "text-no-wrap sticky-header" },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SALÓN", value: "salon", class: "text-no-wrap sticky-header" },
        { text: "ADULTOS", value: "adultos", class: "text-no-wrap sticky-header text-center", },
        { text: "NIÑOS", value: "ninos", class: "text-no-wrap sticky-header text-center", },
        { text: "TOTAL", value: "total", class: "text-no-wrap sticky-header text-center", },
        { text: "TIPO BODA", value: "tipoBoda", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "TIPO ENLACE", value: "tipoEnlace", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "UBICACIÓN ENLACE", value: "ubicacionEnlace", class: "text-no-wrap sticky-header" },
        { text: "APERITIVO", value: "aperitivo", class: "text-no-wrap sticky-header" },
        { text: "FECHA RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "fechaReserva" },
        { text: "BREVE DATO", value: "breveDato", class: "text-no-wrap sticky-header" },
        { text: "ORIGEN RESERVA", value: "origenReserva", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "HABITACIONES", value: "habitaciones", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONTRATO", value: "contrato", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "FIANZA", value: "fianza", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "PB", value: "fechaPrueba", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters4[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  mounted() {
    this.getEventos();
  },
  methods: {
    open(){
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async getEventos() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/boda`,
      });

      this.eventos = data.map((m) => {
        return m;
      });

      this.loading = false;
    },
    select(i) {
      this.resolve(i)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  },
};
</script>

<style>
</style>
<style>.espaciador {
  padding: 5px !important
}</style>